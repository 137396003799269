import React from 'react';
import { styled } from '@stitches/react';
import { MarketingFooter } from './Footer';
import { MarketingNav } from './MarketingNav';

const PageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  justifyContent: 'space-between',
  width: '100%',
  variants: {
    background: {
      lupine: {
        backgroundColor: '$lupine-50',
      },
      white: {
        backgroundColor: 'white',
      },
    },
  },
  defaultVariants: {
    background: 'lupine',
  },
});

const MainContent = styled('main', {
  margin: 'auto',
  width: '100%',
  position: 'relative',
  paddingBottom: '8rem',
});

type BackgroundVariant = 'lupine' | 'white';

interface MarketingPageProps {
  children: React.ReactNode;
  background?: BackgroundVariant;
}

export const MarketingPage: React.FC<MarketingPageProps> = ({ 
  children, 
  background = 'lupine' 
}) => {
  return (
    <>
      <PageContainer background={background}>
        <MarketingNav background={background} />
        <MainContent>{children}</MainContent>
        <MarketingFooter background={background} />
      </PageContainer>
    </>
  );
};