import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';
import { WolfiaLogo } from '../logo/WolfiaLogo';
import { redirectToLoginOrDashboard } from "@/utils/url";

interface MarketingNavProps {
  background?: 'lupine' | 'white';
}

export const MarketingNav: React.FC<MarketingNavProps> = ({ background = 'lupine' }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const bgClass = background === 'lupine' ? 'bg-lupine-50/85' : 'bg-white/85';

  return (
    <div className={`py-4 md:py-6 sticky top-0 inset-x-0 backdrop-blur-xl z-10 ${bgClass}`}>
      <nav className="max-w-5xl px-4 mx-auto flex justify-between items-center">
        <WolfiaLogo width={32} height={32} />
        <div className="hidden md:block">
          <NavLinks />
        </div>

        <button
          type="button"
          onClick={() => setIsMenuOpen((open) => !open)}
          aria-label="toggle menu"
          className="md:hidden text-2xl text-plum-500 p-2 -mr-2"
        >
          <Bars3Icon width="1em" height="1em" />
        </button>

        <div
          className={`${
            isMenuOpen ? 'translate-x-0' : '-translate-y-[200%]'
          } transition-transform top-0 right-0 left-0 fixed bg-plum-50 p-4 flex flex-col items-end gap-8 drop-shadow-lg md:hidden`}
        >
          <button
            type="button"
            onClick={() => setIsMenuOpen((open) => !open)}
            aria-label="close menu"
            className="md:hidden text-2xl text-plum-500 p-2 -mr-2"
          >
            <XMarkIcon width="1em" height="1em" />
          </button>
          <NavLinks />
        </div>
      </nav>
    </div>
  );
};

const NavLinks = () => {
  return (
    <ul className="flex flex-col md:flex md:flex-row gap-6">
      <NavLink href="/case-studies">Customers</NavLink>
      <NavLink href="/blog">Blog</NavLink>
      <NavLink href="/pricing">Pricing</NavLink>
      <NavLink href="/about">About</NavLink>
      <li>
        <Link
          href={redirectToLoginOrDashboard()}
          className="font-sans text-center text-base text-plum-500 py-3 px-4 md:-my-3 rounded-full border block border-plum-300 bg-plum-100 hover:border-plum-500 hover:bg-plum-200 hover:text-plum-700 transition-colors outline-none focus-visible:outline-plum-500 outline-offset-2"
        >
          Log in
        </Link>
      </li>
    </ul>
  );
};

const NavLink = ({ href, children }: { href: string; children: ReactNode }) => {
  const router = useRouter();
  const isActive = router.pathname.startsWith(href);

  const activeClassName = isActive
    ? 'text-plum-500 before:left-3 before:right-3'
    : 'hover:text-plum-500 hover:before:left-3 hover:before:right-3 focus-visible:before:left-3 focus-visible:before:right-3';

  return (
    <li className="flex justify-end md:block">
      <Link
        href={href}
        className={`block w-max text-fog-900 focus-visible:text-plum-500 transition-colors p-3 md:-m-3 relative before:absolute before:h-[2px] before:left-1/2 before:right-1/2 before:bottom-1 before:rounded-full before:bg-plum-500 before:transition-all outline-none ${activeClassName}`}
      >
        <span className="font-sans md:text-base text-lg font-medium">
          {children}
        </span>
      </Link>
    </li>
  );
};
